<template>
  <div id="app">
    <v-app class="transparent_bg">
      <div class="list-table">
        <v-container grid-list-xl>
          <v-snackbar v-model="showSnackbar" :timeout="5000">
            {{ snackbarText }}
            <v-btn color="primary" text @click="showSnackbar = false">
              Close
            </v-btn>
          </v-snackbar>

          <v-layout row wrap>
            <v-flex lg12>
              <v-card>
                <v-toolbar card color="white">
                  <v-text-field
                    flat
                    solo
                    v-model="search"
                    prepend-icon="search"
                    placeholder="Filtrar"
                    hide-details
                    class="hidden-sm-and-down"
                  ></v-text-field>

                  <v-btn dark color="primary" small @click="_createPeople()">
                    Criar Pessoa
                  </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                  <v-data-table
                    :headers="complex.headers"
                    :search="search"
                    :items="complex.items"
                    pagination.sync="query"
                    :rows-per-page-items="[1000, 2000, 5000]"
                    class="elevation-0"
                    item-key="_id"
                    @update:pagination="_nextPage"
                  >
                    <template
                      slot="items"
                      slot-scope="props"
                      class="justify-center"
                    >
                      <td>{{ props.item.name }}</td>
                      <td>{{ props.item.role }}</td>
                      <td class="text-xs-right">
                        <v-btn
                          fab
                          dark
                          color="primary"
                          small
                          @click="_editPeople(props.item)"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>

                        <v-btn
                          fab
                          dark
                          color="primary"
                          small
                          @click="_callDialogConfirmation(props.item)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </td>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </v-app>
    <confirmation-dialog
      :show.sync="showDeleteConfirmation"
      :confirmationAction="_callDeletePeople"
      :dismissAction="() => (this.showDeleteConfirmation = false)"
      :loading="loading"
      message="Tem certeza que deseja excluir esta pessoa?"
    >
    </confirmation-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog';

export default {
  components: { ConfirmationDialog },
  data() {
    return {
      showSnackbar: false,
      loading: false,
      showDeleteConfirmation: false,
      snackbarText: '',
      currentCompany: null,
      peopleToDelete: null,
      query: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'name',
        totalItems: 0
      },
      search: '',
      complex: {
        headers: [
          {
            text: 'Nome',
            value: 'name'
          },
          {
            text: 'Role',
            value: 'papel'
          },
          {
            text: '',
            value: '',
            sortable: false
          }
        ],
        items: []
      }
    };
  },
  computed: {},

  async mounted() {
    this.complex.items = [];
    try {
      this.complex.items = await this._getCompanyPeople({
        query: this.query,
        companyId: this.$route.params.id
      });
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    ...mapActions({
      _getCompanyPeople: 'getCompanyPeople',
      _deleteUser: 'deletePeople'
    }),

    _searchPeople(query) {
      console.log(query);
    },

    async _nextPage(query) {
      await this._getCompanyPeople({ companyId: this.$route.params.id, query });
      this.complex.items = this.getCompanies;
    },

    _createPeople() {
      this.$router.push(`/company/${this.$route.params.id}/people/create`);
    },

    _editPeople(people) {
      this.$router.push(
        `/company/${this.$route.params.id}/people/${people._id}`
      );
    },

    _callDialogConfirmation(people) {
      this.showDeleteConfirmation = true;
      this.peopleToDelete = people;
    },

    async _callDeletePeople() {
      this.loading = true;
      try {
        await this._deleteUser(this.peopleToDelete);
        this.complex.items = await this._getCompanyPeople({
          query: this.query,
          companyId: this.$route.params.id
        });
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
      this.showDeleteConfirmation = false;
    }
  }
};
</script>

<style lang="css" scoped></style>
